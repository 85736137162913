.bg__menu{
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	background-color: rgba(#000, 0.6);
	animation: fadeIn 0.5s linear;
	z-index: 1050;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}
.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.login__icone,
.carrinho__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 5px;
	width: 34px;
	height: 34px;
	background-color: #00a85a;
	min-width: 34px;
}
.segura__login__topo{
    font-family: 'montserratregular';
	font-size: 13px;
	line-height: 15px;
	color: #fff;
	position: relative;

	a{
		&:hover{
			text-decoration: none;
		}
	}


    .login__toggler{
        display: flex;
        align-items: center;

        &:hover{
            text-decoration: none;
        }
    }
    .dropdown{
        display: none;
    }

    &.logado{
        .login__toggler{
            display: none;
        }
        .dropdown{
            display: block;
        }
    }
}
.login__texto{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.logado__toggler{
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	font-family: 'montserratregular';
	font-size: 13px;
	line-height: 15px;
	color: #fff;
	padding: 5px 0px;

	&:focus{
		outline: none;
	}
}
.encerrar__item{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .encerrar__link{
        width: 198px;
        max-width: 100%;
        height: 50px;
        background-color: #000;
        color: #fff;
        font-family: 'montserratbold';
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px;
        transition: all 0.3s linear;
		padding-top: 2px;

		@include media-breakpoint-down(md) {
            height: 28px;
        }

        &:hover{
            background-color: #00a85a;
            color: #fff;
            text-decoration: none;
        }
    }
}
.logado__nome{
    padding: 28px 20px 22px 20px;
    background-color: #00a85a;
    color: #fff;
    font-family: 'montserratregular';
    font-size: 13px;
    line-height: 16px;

	@include media-breakpoint-down (md) {
        padding: 12px 6px 10px 6px;
    }
}
.logado__item{
    padding: 0px 10px;
	background-color: #fff;

    .logado__link{
        display: block;
        padding: 22px 10px 20px 10px;
        border-bottom: 1px solid rgba(#000, 0.1);
        font-size: 13px;
        line-height: 15px;
        font-family: 'montserratregular';
        color: #000;

        &:hover{
            font-family: 'montserratbold';
            color: #00a85a;
			text-decoration: underline;
        }

		@include media-breakpoint-down (md) {
            padding: 10px 6px 10px 6px;
        }
    }
}
.dropdown-menu{
	border: none;
	padding: 0px;
	margin: 0px;
}
.segura__carrinho__topo{

	a{
		display: flex;
		align-items: center;

		&:hover{
			text-decoration: none;
		}
	}
}
.carrinho__link__topo{
	position: relative;
	display: inline-block;
	font-size: 13px;
	line-height: 15px;
	color: #fff;

	&:hover{
		color: #fff;
	}
}
.topo__pesquisa{
	position: fixed;
	width: 90%;
	max-width: 800px;
	top: -200px;
	z-index: 1080;
	transition: all 0.5s linear;
	left: 50%;
	transform: translateX(-50%);

	&.shown{
		top: 49vh;
	}
}
.main__pesquisa{
	min-width: 530px;
	margin-right: 34px;
}
.pesquisa__form{
	display: flex;
	border: 1px solid #fff;

	label{
		display: flex;
		width: 100%;
		margin-bottom: 0px;
	}
	.pesquisa__input{
		width: 100%;
		display: block;
		border: none;
		background-color: #1f392c;
		color: #fff;
		padding: 20px 12px;
		font-size: 13px;
		line-height: 15px;

		&::placeholder{
			color: #fff;
		}
	}
	.pesquisa__btn{
		display: flex;
		width: 55px;
		height: 55px;
		min-width: 55px;
		background-color: #00a85a;
		color: #fff;
		font-size: 22px;
		line-height: 22px;
		border: none;
		align-items: center;
		justify-content: center;
	}
}
.contato__box__icone{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	color: #00a85a;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	min-width: 24px;
}
.segura__bem__vindo{
	font-family: 'montserratextrabold';
}
@include media-breakpoint-up (lg) {
	.topo__mobile{
		display: none;
	}
	.logado__menu{
		min-width: 256px;
        max-width: 100%;
        left: calc(50% - 128px) !important;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
	}
	.logado__toggler{
        position: relative;
        padding: 15px 0px;

        &:after{
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 15px solid #00a85a;
            content: '';
            position: absolute;
            left: calc(50% - 14px);
            bottom: -3px;
            display: none;
			z-index: 100;
        }

        &[aria-expanded="true"] {
            &:after{
                display: block;
            }
        }
    }
	.topo__main{
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
		.topo__cima{
			background-color: #00a85a;
			color: #fff;
			font-size: 12px;
			line-height: 14px;
			padding: 15px 0px;
		}
		.topo__meio{
			background-color: #1f392c;
			color: #fff;
			padding: 15px 0px;
		}
		.main__right{
			display: flex;
			align-items: center;
		}
	}
	.mobile__icone{
		display: none !important;
	}
	.segura__carrinho__topo{
		margin-left: 20px;
	}
	.contato__topo{
		display: flex;
		align-items: center;

		.contato__box{
			display: flex;
			align-items: center;
			margin-left: 16px;

			.contato__box__icone{
				margin-right: 5px;
			}
			.contato__box__titulo{
				font-family: 'montserratextrabold';
				margin-right: 5px;
			}
		}
	}
	.topo__baixo{
		overflow: hidden;

		.main__menu{
			position: relative;
			background-color: #fff;
			display: flex;
			width: 100%;
			max-width: 816px;
			justify-content: space-between;
			padding: 0px 60px;
			align-items: center;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;
			line-height: 18px;

			.menu__link{
				display: flex;
				padding: 40px 0px 40px 0px;
				color: #00a85a;
				font-family: 'montserratregular';

				&:hover{
					font-family: 'montserratbold';
				}
			}

			&:after,
			&:before{
				position: absolute;
				height: 10px;
				background-color: #ad5e2f;
				width: 100vw;
				content: '';
				top: 50%;
				transform: translateY(-50%);
				z-index: -1;
			}
			&:after{
				left: 0px;
			}
			&:before{
				right: 0px;
			}
		}
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		position: fixed;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		z-index: 1080;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #1f392c;
		color: #fff;
		display: flex;
		flex-direction: column;

		.container{
			padding: 0px;
		}

		.topo__cima{
			order: 3;
		}
		.topo__meio{
			order: 1;
		}
		.topo__baixo{
			order: 2;
		}
		.main__menu{
			margin-bottom: 20px;

			.menu__link{
				display: block;
				padding: 10px;
				background-color: #fff;
				color: #1f392c;
				font-family: 'montserratbold';
				font-size: 16px;
				line-height: 16px;
				border-bottom: 1px solid #1f392c;
				transition: all 0.3s linear;

				&:hover{
					background-color: #00a85a;
					color: #fff;
					text-decoration: none;
				}
			}
		}

		.segura__bem__vindo{
			display: none;
		}
		.segura__carrinho__topo{
			display: none;
		}
		.segura__login__topo{
			display: none;
		}
		.main__pesquisa{
			display: none;
		}

		.contato__topo{
			padding: 0px 10px;
			display: flex;
			align-items: center;
			flex-direction: column;
			text-align: center;

			.contato__box{
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 15px;

				.contato__box__icone{
					margin-right: 0px;
					margin-bottom: 5px;
				}
				.contato__box__conteudo{
					display: flex;
					flex-direction: column;
					align-items: center;
					text-align: center;
				}
			}

			.barrinha{
				display: none;
			}
		}

		&.shown{
			left: 0px;
		}
	}
	.segura__bem__vindo__mobile{
		background-color: #00a85a;
		padding: 5px 15px;
		color: #fff;
		text-align: center;
	}
	.mbl__toggler{
		border: none;
		background-color: transparent;
		color: #fff;
		font-size: 20px;
		line-height: 24px;
		margin: 0px 5px;
	}
	.topo__mobile{
		border-bottom: 3px solid #00a85a;
		background-color: #1f392c;

		.container{
			display: grid;
			grid-template-columns: 1fr minmax(auto, 80px) 1fr;
			padding: 0px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}
	.mbl__logo{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main__logo{
		padding: 10px;
		text-align: center;
		margin-bottom: 10px;
	}
	.login__toggler,
	.logado__toggler{
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		padding: 10px;
		border-bottom: 2px solid #00a85a;
		width: 100%;
	}
	.logado__menu{
        min-width: 200px;
        max-width: 100%;
        border-radius: 0px;
        border: none;
        padding: 0px;
        box-shadow: 0px 6px 10px rgba(#000, 0.6);
        left: -3px !important;

        .logado__link{
            font-family: 'montserratbold';
        }
    }
	.btn__left__topo{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.carrinho__link__topo{
			margin-left: 5px;

			.carrinho__icone,
			.carrinho__topo__texto{
				display: none;
			}
		}

		.mobile__login{
			margin-left: 10px;

			.segura__login__topo{
				.login__toggler,
				.logado__toggler{
					padding: 15px 0px;
					border: none;

					&[aria-expanded="true"] {
						&:after{
							display: block;
						}
					}

					&:after{
						border-left: 14px solid transparent;
						border-right: 14px solid transparent;
						border-bottom: 15px solid #00a85a;
						content: '';
						position: absolute;
						left: -3px !important;
						bottom: 0px;
						display: none;
						z-index: 100;
					}

					.login__texto{
						display: none;
					}
					.login__icone{
						display: none;
					}
				}
			}
		}
	}
	.btn__right__topo{
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.mobile__carrinho{
			margin-right: 10px;

			.mobile__icone{
				position: relative;
				display: flex;
				align-items: center;

				.carrinho__numero__mobile{
					font-size: 12px;
					line-height: 12px;
					padding: 3px 5px;
					background-color: #00a85a;
					color: #fff;
					font-family: 'montserratextrabold';
					order: 2;
					margin: 0px 3px;
				}
				span{
					font-size: 20px;
					line-height: 20px;
					order: 1;
				}
			}
		}
	}
	.contato__box__titulo{
		font-family: 'montserratbold';
	}
}
