.interna{
    padding: 90px 0px;
}
.titulo__interna{
    font-family: 'montserratextrabold';
    font-size: 40px;
    line-height: 44px;
    color: #00a85a;
    margin-bottom: 50px;
}
.minha__conta__nav{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
	font-family: 'montserratextrabold';

    a{
        display: block;
        padding: 15px 10px;
        font-size: 15px;
        line-height: 15px;
        max-width: 300px;
        border-bottom: 1px solid rgba(#1f392c, 0.4);
        width: 100%;
		text-align: center;

		&:hover{
			color: #00a85a;
			text-decoration: none;
		}
    }
}
.conta__interna{
	.titulo__interna{
		text-align: center;
	}
}
.conteudo__btn{
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: #1f392c;
	color: #fff;
	min-width: 250px;
	height: 60px;
	transition: all 0.3s linear;
	font-family: 'montserratbold';
	font-size: 15px;
	line-height: 15px;
	border: none;
	padding-top: 5px;

	&.voltar__btn{
		min-width: 154px;
	}

	&:focus{
		outline: none;
	}

	&:hover{
		background-color: #00a85a;
		color: #fff;
		text-decoration: none;
	}
}
.check__custom{

	input{
		position: fixed;
		left: -200vw;

		&:checked{
			~ label{
				&:after{
					opacity: 1;
				}
			}
		}
	}
	label{
		padding-left: 24px;
		position: relative;
		font-size: 14px;
		line-height: 16px;
		font-family: 'montserratsemibold';
		cursor: pointer;

		&:before{
			position: absolute;
			top: calc(50% - 8px);
			left: 0px;
			width: 16px;
			height: 16px;
			border: 2px solid #1f392c;
			content: '';
			border-radius: 50%;
		}
		&:after{
			width: 8px;
			height: 8px;
			position: absolute;
			left: 4px;
			top: calc(50% - 4px);
			background-color: #1f392c;
			content: '';
			opacity: 0;
			border-radius: 50%;
		}
	}
}
.btn__center{
	padding-top: 20px;
    text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.btn__left{
    padding-top: 20px;
    text-align: center;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.btn__right{
    padding-top: 20px;
    text-align: center;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.interna__input,
.contato__input{
    width: 100%;
    display: block;
    height: auto;
    padding: 16px 24px 14px 24px;
    border: none;
    background-color: #ededed;
    font-size: 14px;
    line-height: 16px;
    color: #1f392c;
    font-family: 'montserratextrabold';
    resize: none;

    &::placeholder{
        color: #1f392c;
    }
    &:focus{
        outline: none;
    }
}
.endereco__form,
.custom__form{
	.v-select-output{
		display: block;
		width: 100%;
		height: auto;
		padding: 16px 24px 14px 24px;
		background-color: #ededed;
		font-family: 'montserratregular';
		font-size: 14px;
		line-height: 16px;
		color: #1f392c;
		border: none;
		border-radius: 0px;

		&:focus{
			box-shadow: none;
		}

		&:before{
			margin-top: 4px;
		}
	}
	.v-options{
		font-size: 13px;
		line-height: 15px;
		margin: none;
		max-height: calc(36px * 4);
		border-radius: 0px;
        z-index: 500;
		box-shadow: 0px 0px 5px rgba(#1f392c, 0.3);
		background-color: #ededed;

		li{
			color: #1f392c;
			font-family: 'montserratmedium';

			&.selected{
				background-color: #1f392c;
				color: #fff;
			}
		}
	}
}
