.categoria__header{
	background-color: #1f392c;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	text-align: center;
    padding: 16px 20px 14px;
	margin-bottom: 20px;
}
.titulo__categoria{
	font-family: 'montserratextrabold';
	font-size: 15px;
	line-height: 18px;
	color: #00a85a;
	margin-bottom: 14px;
}
.categoria__box{
	padding-top: 20px;
	padding-left: 30px;
	padding-right: 30px;

	&:last-child{
		padding-bottom: 20px;
	}
}
.categoria__lista{
	padding-left: 0px;
	margin-bottom: 0px;
	list-style: none;
}
.categoria__item__interna{
	display: flex;
	font-family: 'montserratsemibold';
	font-size: 14px;
	line-height: 18px;
	color: #1f392c;
	margin-bottom: 4px;

	&:hover{
        text-decoration: underline;
        color: #1f392c;
        font-weight: 900;
	}
}
.categoria__quant{
	margin-left: 5px;
}
.categoria__mais{
	border: none;
	background-color: transparent;
	padding: 0px;
	font-family: 'montserratextrabold';
	font-size: 14px;
	line-height: 15px;
	color: #00a85a;
	margin-top: 5px;
	display: inline-block;

    span{
        font-size: 12px;
        line-height: 14px;
    }

	&:focus{
		outline: none;
	}
}
.categoria__segura__radio{
	.categoria__radio{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .categoria__label{

				&:before{
					border-color: #1f392c;
					background-color: #fff;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
	.categoria__label{
		padding-left: 20px;
		position: relative;
		font-family: 'montserratsemibold';
		font-size: 14px;
		line-height: 18px;
		color: #1f392c;
		transition: all 0.3s linear;
		cursor: pointer;

		&:hover{

			&:before{
				border-color: #1f392c;
				background-color: #fff;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 30%;
			transform: translateY(-50%);
			left: 0px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid #1f392c;
            transition: all 0.3s linear;
            margin-top: 2px;
		}

		&:after{
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: #00a85a;
			top: 30%;
			left: 3px;
			transform: translateY(-1px);
			transition: all 0.3s linear;
			opacity: 0;
		}
	}
}
.categoria__interna{
    .col-xl-9{
        margin-bottom: 10px;

        .titulo__interna{
            margin-bottom: 10px;
        }

        .col-lg-6{
            margin-bottom: 20px;
        }
    }
    .paginacao{
        padding-top: 30px;
    }
}
.categoria__mais{
	&[aria-expanded="true"] {
		&.categ__mais{
			display: none;
		}
	}
	&[aria-expanded="false"] {
		&.categ__menos{
			display: none;
		}
	}
}
.paginacao{
	display: flex;
	justify-content: flex-end;
    flex-wrap: wrap;
    border-radius: none;
    border: none;
    list-style: none;
    padding-left: 0px;

	.paginacao__item{
		a{
            font-family: 'montserratsemibold';
            font-size: 17px;
            line-height: 17px;
            color: #1f392c;
            display: flex;
            width: 34px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            height: 34px;
            border-radius: 50%;
            position: relative;
            transition: all 0.3s linear;
			border: none;
            margin: 0px 2px;
            padding: 0px;
            padding-left: 1px;
            padding-top: 1px;

            &:focus{
                box-shadow: none;
            }
        }

        &.active{
           a{
                background-color: #00a85a;
                color: #fff;
                text-decoration: underline;
           }
        }

		&:hover{
			a{
                text-decoration: underline;
            }
		}
	}
}
.categorias__listagem{
    border-bottom: 1px solid #1f392c;
	border-right: 1px solid #1f392c;
	border-left: 1px solid #1f392c;
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
.depoimento__card{
    margin-bottom: 40px;
    color: #1f392c;

    .depoimento__desc{
        text-align: justify;
        margin-bottom: 4px;
    }
    .depoimento__titulo{
        font-family: 'montserratbold';
    }
}
.subcategoria__lista{
	padding-left: 15px;
	list-style: none;
	margin-bottom: 5px;

	.subcategoria__item{
		margin-bottom: 5px;

		.categoria__item__interna{
			font-size: 14px;
			line-height: 18px;
			font-family: 'montserratregular';
		}
	}
}
.segura__categoria__controls{
	display: flex;
	align-items: center;

	button{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		background-color: #1f392c;
		color: #fff;
		border-radius: 50%;
		border: none;
		min-width: 18px;
		min-height: 18px;
		width: 18px;
		height: 18px;
		padding: 0px;
		font-size: 8px;
		line-height: 8px;
		margin-left: 5px;
		margin-bottom: 2px;
		transition: all 0.3s linear;
		padding-top: 1px;

		&:focus{
			outline: none;
		}
		&[aria-expanded="true"] {
			transform: rotate(90deg);
		}
	}
}
.paginacao__custom{
	display: flex;
	justify-content: flex-end;
	padding-top: 20px;

	.paginacao__link{
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #1f392c;
		color: #fff;
		width: 34px;
		height: 30px;
		transition: all 0.3s linear;
		margin: 2px;

		&.disabled{
			opacity: 0.3;
			pointer-events: none;
		}

		&:hover{
			background-color: #ad5e2f;
		}
	}
}
@include media-breakpoint-down (md) {
	.categorias__listagem{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (lg) {
    .categoria__interna{
        .produto__card{
            .produto__valores{
                flex-direction: column;
                align-items: center;

                .produto__valor__promocao{
                    padding-right: 0px;

                    &:after{
                        display: none;
                    }
                }
                .produto__valor{
                    padding-left: 0px;
                }
            }
        }
    }
}
