.banner__carousel{
	max-width: 1920px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: relative;

	.carousel-inner{
		max-width: 1920px;
		width: 160%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.carousel-indicators{
		justify-content: flex-end;
		bottom: 30px;

		li{
			position: relative;
			width: 22px;
			height: 22px;
			border: 2px solid #fff;
			border-radius: 50%;
			background-color: transparent;
			opacity: 1;

			&.active{
				&:after{
					opacity: 1;
				}
			}

			&:after{
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				background-color: #fff;
				border-radius: 50%;
				top: calc(50% - 7px);
				left: calc(50% - 7px);
				opacity: 0;
				transition: all 0.3s linear;
			}
		}
	}
}
.info{
	padding: 30px 0px;
	background-color: #1f392c;
	color: #fff;
}
.info__card{
	display: flex;
	align-items: center;
	font-family: 'montserratsemibold';
	font-size: 12px;
	line-height: 16px;
	justify-content: center;

	.info__card__icone{
		margin-right: 15px;
	}
}
.info__card__titulo{
	font-size: 18px;
	line-height: 22px;
}
.institucional__titulo{
	font-family: 'montserratbold';
	font-size: 40px;
	line-height: 44px;
	margin-bottom: 16px;
	color: #00a85a;
}
.institucional__desc{
	text-align: justify;
	font-size: 13px;
	line-height: 16px;
	margin-bottom: 70px;
}
.institucional__item{
	display: flex;
	align-items: center;
	max-width: 390px;
	width: 100%;

	.institucional__icone{
		margin-right: 20px;
	}
}
.institucional__item{
	margin-bottom: 42px;
}
.institucional__item__titulo{
	font-family: 'montserratextrabold';
	font-size: 18px;
	line-height: 20px;
	color: #ad5e2f;
	margin-bottom: 6px;
}
.institucional__area{
	padding: 76px 0px 122px 0px;
}
.institucional__imagem{
	position: relative;
	padding-bottom: 60px;

	.institucional__texto{
		position: absolute;
		min-width: 260px;
		max-width: 100%;
		min-height: 120px;
		display: inline-flex;
		align-items: center;
		justify-content:center;
		text-align: center;
		background-color: #1f392c;
		color: #fff;
		left: 50%;
		bottom: 0px;
		transform: translateX(-50%);
		border-radius: 10px;
		font-family: 'montserratbold';
		font-size: 34px;
		line-height: 38px;
		padding: 10px;
	}
}
.segura__secoes__1{
	position: relative;

	&:after{
		z-index: -1;
		content: '';
		position: absolute;
		width: 1920px;
		height: 100%;
		background-image: url(../images/folha__cima.png);
		background-repeat: no-repeat;
		background-position: right 576px;
		background-size: auto;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
	}
}
.produto__card{
	max-width: 350px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
	margin-bottom: 42px;

	a{
		&:hover{
			text-decoration: none;
		}
	}

	.produto__card__link__grande{
		position: relative;

		.produto__card__imagem{
			position: relative;
			border: 1px solid #6e6b6b;
			margin-bottom: 20px;
			display: block;

			.produto__card__tags{
				position: absolute;
				top: 10px;
				right: 10px;
				z-index: 15;
				display: flex;
				flex-direction: column;

				.produto__card__tag{
					display: inline-flex;
					align-items: center;
					justify-content: center;
					background-color: #1f392c;
					min-width: 95px;
					height: 30px;
					border-radius: 15px;
					padding: 5px 10px;
					margin-bottom: 10px;
					font-family: 'montserratbold';
					font-size: 13px;
					line-height: 13px;
					color: #fff;
				}
			}
		}
	}
}
.produto__titulo__destaque{
	font-family: 'montserratbold';
	font-size: 40px;
	line-height: 44px;
	color: #1f392c;
	text-align: center;
	margin-bottom: 40px;
}
.produto__card__titulo{
	font-family: 'montserratextrabold';
	color: #1f392c;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	max-width: 230px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}
.produto__card__parcelamento{
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	margin-bottom: 10px;
}
.produto__card__valor{
	display: flex;
	justify-content: center;
	align-items: flex-end;
	font-family: 'montserratextrabold';
	font-size: 15px;
	line-height: 15px;
	color: #00a85a;
	margin-bottom: 8px;

	.produto__valor__promocao{
		margin-right: 5px;
		font-size: 13px;
		line-height: 13px;
		text-decoration: line-through;
	}
}
.produto__card__botoes{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.favorito__botao,
	.carrinho__botao{
		background-color: #1f392c;
		color: #fff;
		border: none;
		padding: 8px 14px;
		border-radius: 10px;
		margin: 5px;
		display: inline-flex;
		align-items: center;
		transition: all 0.3s linear;

		&:hover{
			background-color: #C35E26;
			color: #1f392c;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}

		.carrinho__card{
			margin-right: 5px;
		}
	}
	.favorito__botao{
		padding: 8px;
	}
}
.produtos__bs{
	display: flex;
	flex-direction: column;

	.carousel-inner{
		order: 2;
	}

	.carousel-controls{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		order: 1;
		margin-bottom: 20px;

		.carousel__prev,
		.carousel__next{
			width: 34px;
			height: 30px;
			background-color: #1f392c;
			color: #C35E26;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 5px;
			transition: all 0.3s linear;

			&:hover{
				background-color: #C35E26;
				color: #fff;
				text-decoration: none;
			}

			&:focus{
				outline: none;
			}
		}
	}
}
.produtos__promocoes{
	padding: 92px 0px 70px 0px;
}

.paisagismo{
	@include media-breakpoint-up (lg) {
		max-width: 1920px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		position: relative;

		.paisagismo__inner{
			max-width: 1920px;
			width: 160%;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}

		.row__custom{
			display: flex;
			justify-content: center;

			.row__custom__col__texto{
				min-width: 50%;
				width: 50%;
				display: flex;
				justify-content: flex-end;

				.conteudo__custom__texto{
					max-width: 600px;
					width: 100%;
					padding: 0px 15px;

					@include media-breakpoint-only (lg) {
						max-width: 480px;
					}
				}
			}
			.row__custom__col__imagem{
				min-width: 50%;
				width: 50%;
				padding-left: 15px;
			}

			.conteudo__custom__texto{
				padding-bottom: 420px;
				position: relative;

				&:before{
					content: '';
					position: absolute;
					right: 0px;
					bottom: 0px;
					width: 581px;
					height: 401px;
					background-image: url('../images/detalhe__paisagismo.png');
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center center;
				}
			}
		}
	}
}
.paisagismo__titulo{
	font-family: 'montserratbold';
	font-size: 40px;
	line-height: 44px;
	color: #ad5e2f;
	margin-bottom: 20px;
}
.paisagismo__desc{
	text-align: justify;
	margin-bottom: 90px;
}
.paisagismo__botao{
	display: inline-flex;
	width: 336px;
	height: 90px;
	align-items: center;
	justify-content: center;
	padding: 20px 10px 10px 10px;
	background-color: #1f392c;
	color: #fff;
	font-family: 'montserratsemibold';
	font-size: 15px;
	line-height: 15px;
	transition: all 0.3s linear;
	border: none;

	&:hover{
		background-color: #00a85a;
		color: #fff;
		text-decoration: none;
	}

	&:focus{
		outline: none;
	}
}
@include media-breakpoint-down (md) {
	.row__custom__col__texto{
		margin-bottom: 40px;
	}
	.row__custom__col__imagem{
		text-align: center;
	}
}
.opcoes__secao{
	background-color: #00a85a;
	color: #fff;
}
.opcoes__grid{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 28px;
	row-gap: 28px;

	@include media-breakpoint-down (md) {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 15px;
		row-gap: 15px;

		@include media-breakpoint-down (xs) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
.acoes__card{
	display: block;
	position: relative;
	max-width: 352px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:hover{
		.acoes__card__titulo{
			opacity: 1;
		}
	}

	img{
		position: relative;
		z-index: 5;
	}
	.acoes__card__titulo{
		position: absolute;
		top: 0px;
		left: 0px;
		font-family: 'montserratextrabold';
		color: #fff;
		text-align: center;
		max-width: 100%;
		width: 100%;
		height: 100%;
		display: flex;
		background-color: rgba(#1f392c, 0.45);
		z-index: 10;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		line-height: 22px;
		transition: all 0.3s linear;
		opacity: 0;
	}
}
.opcoes__secao{
	padding: 36px 0px 56px 0px;
}
.opcoes__titulo{
	font-family: 'montserratbold';
	font-size: 40px;
	line-height: 44px;
	color: #fff;
	margin-bottom: 46px;
}
@include media-breakpoint-down (md) {
	.info__card{
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		text-align: center;

		.info__card__icone{
			margin-right: 0px;
			margin-bottom: 10px;
		}
	}
	.institucional__imagem{
		text-align: center;
		margin-bottom: 30px;
	}
}
