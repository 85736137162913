.perdeu__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.sub__form{
	font-family: 'montserratextrabold';
	font-size: 30px;
	line-height: 34px;
	color: #00a85a;
	margin-bottom: 30px;
}
.perdeu__texto{
    color: #1f392c;
    margin-bottom: 10px;
}
.perdeu__btns{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
	padding-top: 20px;

    & > * {
        margin: 5px;
    }
}
.check__contato{
    &.segura__check{
        padding-top: 10px;

        .check__label__cadastro{
            font-weight: 400;
            margin-bottom: 10px;

            &:before{
                top: 10px;
            }
            &:after{
                top: 10px;
            }
        }
    }
}
.contato__btn{
    padding-top: 10px;
}
.perdeu__form{
    .titulo__interna{
        margin-bottom: 40px;
    }
}
.bs__interna{
	position: relative;
	display: flex;
	flex-direction: column;

	.carousel-inner{
		order: 1;
	}

	.carousel-indicators{
		position: relative;
		padding-top: 20px;
		margin-bottom: 20px;
		order: 2;

		li{
			width: 17px;
			height: 17px;
			border-radius: 50%;
			background-color: #00a85a;
			margin: 0px 8px;
			border: none;
			opacity: 1;

			&.active{
				background-color: #C35E26;
			}
		}
	}
}
