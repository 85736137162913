.segura__alterar__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.dados__form{
    .form-group{
        margin-bottom: 10px;
    }
}
.dados__form{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.erro__desc{
    text-align: justify;
    margin-bottom: 30px;
}
.segura__erro{
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.segura__genero{
    color: #1f392c;
}
