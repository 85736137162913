.servico__card{
	display: block;
	max-width: 260px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px;

	.servico__card__conteudo{
		position: relative;
		border: 1px solid #1f392c;
		margin-bottom: 12px;

		.servico__card__desc{
			padding: 0px 16px 32px 16px;
			--linhas: 7;
			color: #1f392c;
			font-size: 13px;
			line-height: 16px;
			text-align: justify;
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
		}
		.servico__card__imagem{
			img{
				transition: all 0.3s linear;
				opacity: 0;
			}
		}
	}
	.servico__card__titulo{
		font-family: 'montserratbold';
		font-size: 16px;
		line-height: 20px;
		height: 40px;
		color: #00a85a;
		text-align: center;
	}

	&:hover{
		text-decoration: none;
		.servico__card__conteudo{
			.servico__card__desc{
				color: #fff;
			}
			.servico__card__imagem{
				img{
					opacity: 1;
				}
			}
		}
	}

	@include media-breakpoint-down (md) {
		.servico__card__conteudo{
			.servico__card__desc{
				color: #fff;
			}
			.servico__card__imagem{
				img{
					opacity: 1;
				}
			}
		}
	}
}
