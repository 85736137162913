.rodape__btn-whatsapp{
	position:fixed;
	bottom:15px;
	left:15px;
	z-index:400;
	background-color:#4caf50;
	border-radius:100%;
	color:#FFF;
	font-size:40px;
	width:(60 / 40) * 1em;
	height:(60 / 40) * 1em;
	line-height:(60 / 40) * 1em;
	@extend .fab;
	@extend .fa-whatsapp;
	display:inline-flex;
	align-items:center;
	justify-content:center;
	box-shadow:0 0 8px rgba(#000,0.5);
	transition:background-color 0.6s linear;

	&:hover,
	&:focus{
		background-color:darken(#4caf50,10%);
		text-decoration:none;
	}

	&:after{
		content:'';
		display:block;
		position:absolute;
		top:calc(50% - 7px);
		left:calc(50% - 7px);
		border-radius:100%;
		z-index:-2;
		width:15px;
		height:15px;
		background-color:#4caf50;
		animation:explodeMidiaMenor 4s infinite;
	}

}

.rodape__conteudo{
	position: relative;
	background-color: #1f392c;
	color: #fff;
	padding-top: 16px;
	overflow: hidden;

	@include media-breakpoint-down (md) {
		text-align: center;

		.row{
			.col-lg-6{
				&:first-child{
					margin-bottom: 30px;
				}
			}
		}
	}

	.container{
		position: relative;
		z-index: 10;
	}

	&:after{
		position: absolute;
		z-index: 1;
		width: 976px;
		left: 50%;
		top: 0px;
		height: 100%;
		background-image: url('../images/rodape__bg.jpg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		content: '';

		@include media-breakpoint-down (md) {
			display: none;
		}
	}

	.rodape__logo{
		margin-bottom: 20px;
	}
}
.row__rodape{
	margin-bottom: 50px;
}
.rodape__item{
	margin-bottom: 12px;
}
.rodape__sociais{
	display: flex;
	align-items: center;
	font-size: 24px;
	line-height: 24px;
	margin-bottom: 10px;

	@include media-breakpoint-down (md) {
		justify-content: center;
	}

	a{
		color: #00a85a;
		display: inline-block;
		margin: 0px 10px;
	}

}
.menu__titulo{
	font-family: 'montserratbold';
	font-size: 13px;
	line-height: 15px;
	margin-bottom: 14px;
}
.menu__rodape{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: 'montserratmedium';

	@include media-breakpoint-down (md) {
		align-items: center;
		margin-bottom: 30px;
	}

	a{
		margin-bottom: 10px;
	}
}
.newsletter__rodape{
	background-color: #fff;
	padding: 46px 60px;
	border-radius: 20px;
	margin-bottom: 85px;

	.newsletter__titulo{
		color: #00a85a;
		font-family: 'montserratbold';
		font-size: 28px;
		line-height: 30px;

		@include media-breakpoint-down (md) {
			margin-bottom: 15px;
		}

		span{
			color: #1f392c;
		}
	}
}
.news__input{
	display: block;
	width: 100%;
	background-color: #ad5e2f;
	color: #fff;
	font-style: italic;
	padding: 18px 15px 17px 15px;
	border: none;
	border-right: 1px solid #fff;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #fff;
	}
}
.news__btn{
	display: flex;
	background-color: #1f392c;
	color: #fff;
	border: none;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	line-height: 30px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #00a85a;
	}
}
.newsletter__form{

	@include media-breakpoint-up (md) {
		display: grid;
		grid-template-columns: calc(50% - 26px) calc(50% - 26px) 52px;
	}
	@include media-breakpoint-down (sm) {
		display: flex;
		flex-direction: column;
		align-items: center;

		.news__input{
			border-bottom: 1px solid #fff;
			border-right: none;
		}
		.news__group{
			width: 100%;
		}
		.news__btn{
			padding: 10px;
			width: 100%;
		}
	}
}
.pagamento__secao{
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 40px;

	.pagamento__titulo{
		margin-right: 10px;
		text-align: right;
		font-family: 'montserratbold';
		font-size: 14px;
		line-height: 16px;
	}
	@include media-breakpoint-down (sm) {
		flex-direction: column;
		align-items: center;

		.pagamento__titulo{
			text-align: center;
			margin-right: 0px;
			margin-bottom: 15px;
		}
	}
}
.rodape__creditos{
	padding: 22px 10px;
	text-align: center;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #1f392c;
		font-family: 'montserratsemibold';
		font-size: 10px;
		line-height: 12px;

		@include media-breakpoint-down (sm) {
			flex-direction: column;
			align-items: center;

			.creditos{
				margin-bottom: 3px;
			}
		}
	}
}

.gv8__box{
	display: flex;
	align-items: center;

	svg{
		margin-left: 5px;
	}
}
@include media-breakpoint-up (lg) {
	.menu__titulo{
		color: #1f392c;
	}
	.menu__rows{
		display: flex;

		.menu__col{
			width: 28%;
			padding: 0px 10px;

			&:nth-child(2) {
				width: 40%;
			}

			&:nth-child(1) {
				width: 32%;
			}
		}
	}
}
